// src/components/Header.jsx
import React from "react";
import { Link } from "react-router-dom";

const Header = () => {
  return (
    <header className="bg-gradient-to-r from-white-100 to-teal-500 text-black shadow-lg p-4">
      <nav className="fixed top-0 left-0 w-full z-50 bg-white shadow-md flex justify-between items-center">
        <Link to="/" className="flex items-center">
          <img
            src="/images/logo.jpg"
            alt="Fabro Plus Logo"
            className="w-20 h-16 mr-2"
          />
        </Link>

        <ul className="hidden md:flex space-x-8">
          <li>
            <Link
              to="/"
              className="hover:text-gray-300 text-lg transition-colors duration-300"
            >
              Who We Are
            </Link>
          </li>
          <li>
            <Link
              to="/shop"
              className="hover:text-gray-300 text-lg transition-colors duration-300"
            >
              What We Do
            </Link>
          </li>
          <li className="relative group">
            {/* Parent Link */}
            <Link
              to="/shop"
              className="hover:text-gray-300 text-lg transition-colors"
            >
              Products
            </Link>

            {/* Dropdown */}
            <ul className="absolute hidden group-hover:block bg-white text-black shadow-lg rounded-lg mt-1 w-40 z-30">
              <li className="hover:bg-gray-100">
                <Link to="/products/mortice" className="block px-4 py-2">
                  Mortice Locks
                </Link>
              </li>
              <li className="hover:bg-gray-100">
                <Link to="/products/aldrops" className="block px-4 py-2">
                  Aldrops
                </Link>
              </li>
              <li className="hover:bg-gray-100">
                <Link to="/products/cabinet-locks" className="block px-4 py-2">
                  Cabinet Locks
                </Link>
              </li>
              <li className="hover:bg-gray-100">
                <Link to="/products/cupboard-locks" className="block px-4 py-2">
                  Cupboard Locks
                </Link>
              </li>
              <li className="hover:bg-gray-100">
                <Link to="/products/door-locks" className="block px-4 py-2">
                  Door Locks
                </Link>
              </li>
              <li className="hover:bg-gray-100">
                <Link to="/products/pad-locks" className="block px-4 py-2">
                  Pad Locks
                </Link>
              </li>
            </ul>
          </li>
          <li>
            <Link
              to="/"
              className="hover:text-gray-300 text-lg transition-colors duration-300"
            >
              Contact Us
            </Link>
          </li>
        </ul>

        {/* Icons */}
        <div className="flex space-x-4 mr-5">
          <Link
            to="/Login"
            className="hover:text-gray-300 text-lg transition-colors duration-300"
          >
            <i className="fas fa-user"></i>
          </Link>
          <Link
            to="/cart"
            className="hover:text-gray-300 text-lg transition-colors duration-300"
          >
            <i className="fas fa-shopping-cart"></i>
          </Link>
        </div>
      </nav>
    </header>
  );
};

export default Header;
