import React from "react";
import ProductCard from "../components/ProductCard";
import EnquiryForm from "../components/EnquiryForm";

export const HomeContainer = () => {
  return (
    <div className="min-h-screen p-8 my-4">
      <div className="flex flex-col md:flex-row items-start justify-start">
        <div className="w-full md:w-3/3 h-[60vh] md:h-[100vh] overflow-y-auto pr-6 bg-white rounded-lg">
          <div className="mb-12">
            <h1 className="text-4xl md:text-5xl font-bold text-gray-800">
              Fabbro Plus Architectural Hardware
            </h1>
          </div>
          <p className="text-gray-700 text-lg mb-4">
            Fabbro Plus Architectural Hardware is a premier name in the hardware
            manufacturing industry, renowned for its exceptional quality and
            innovative solutions. Proudly owned by Doorfit Enterprises, our
            company has established itself as a trusted leader in the market for
            over a decade.
          </p>
          <p className="text-gray-700 text-lg mb-4">
            At Fabbro Plus, we pride ourselves on maintaining the highest
            standards of quality management. Our rigorous quality control
            processes ensure that every product meets our stringent criteria for
            durability and performance. Our dedicated workforce, composed of
            skilled professionals, drives innovation and excellence,
            contributing significantly to our reputation for reliability and
            efficiency.
          </p>
          <p className="text-gray-700 text-lg mb-4">
            We are committed to staying at the forefront of architectural
            hardware design and technology. Our products reflect the latest
            advancements and trends in the industry, combining modern aesthetics
            with functional excellence. This dedication to innovation allows us
            to offer solutions that not only meet but exceed the expectations of
            our discerning clients.
          </p>
        </div>
      </div>

      {/* Offerings */}
      <div className="py-16 bg-gray-100">
        <div className="container mx-auto px-4 text-center mb-12">
          <h2 className="text-3xl md:text-4xl font-bold mb-8">Our Offerings</h2>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
          <ProductCard
            image={"images/mortice.jpg"}
            title="Mortice Locks"
            link="/locks"
          />
          <ProductCard
            image={"images/ALDROP.png"}
            title="Aldrops"
            link="/hardware"
          />
          <ProductCard
            image={"images/CABINET.png"}
            title="Cabinet Locks"
            link="/kitchen-fittings"
          />
          <ProductCard
            image={"images/cupboard.jpg"}
            title="Cupboard Lock"
            link="/locks"
          />
          <ProductCard
            image={"images/DOOR.jpg"}
            title="Door Lock"
            link="/hardware"
          />
          <ProductCard
            image={"images/PAD.png"}
            title="Pad Locks"
            link="/kitchen-fittings"
          />
        </div>
      </div>


      {/* Contact Section */}
      <div className="container mx-auto">
        <div className="flex flex-col lg:flex-row flex-start">
          {/* Registered Address */}
          <div className="w-full lg:w-2/1 p-4">
            <h3 className="text-xl font-bold font-semibold mb-4">Registered Address</h3>
            <div className="overflow-hidden border-2 border-gray-300 rounded-lg h-172 flex items-center justify-center">
              <iframe
                title="Google Map"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3559.7553051703744!2d75.855356110951!3d26.84773407658873!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x396db767df959dbd%3A0x317be1e84a7e473f!2sFabbro%20Plus%20Architectural%20Hardware!5e0!3m2!1sen!2sin!4v1729780359835!5m2!1sen!2sin"
                width="100%"
                height="400"
                style={{ border: 0 }}
                allowFullScreen=""
                loading="lazy"
              ></iframe>
            </div>
          </div>


          {/* Support Box */}
          <div className="w-full lg:w-1/2 p-4 mt-10">
            <div className="border-2 space-y-4 bg-white p-6 rounded-lg shadow-lg">
              <h3 className="text-md text-center font-bold font-regular mb-4">Support</h3>

              {/* Phone number button */}
              <div className="flex items-center space-x-2 text-blue-600 hover:text-blue-700 cursor-pointer">
                {/* <Phone size={20} /> */}
                <i class="fa-solid fa-phone"></i>
                <span className="text-lg">8599999737</span>
              </div>

              {/* Support Centre button */}
              <a href="mailto:dnoddy535@gmail.com">
                <button className="w-full bg-blue-600 hover:bg-blue-700 text-white py-2 px-4 rounded-md flex items-center justify-between">
                  {/* <span>Submit Request to email</span> */}
                  <span className="text-lg">dnoddy535@gmail.com</span>
                  <span className="text-lg">&#8594;</span>
                </button>
              </a>

              {/* Submit Service Request button */}
              <button className="w-full bg-blue-600 hover:bg-blue-700 text-white py-2 px-4 rounded-md flex items-center justify-between">
                <span>WHATSAPP DIRECT MESSAGE</span>
                <span className="text-lg">&#8594;</span>
              </button>
            </div>
          </div>

        </div>

        {/* Enquiry Form */}
        <div className="flex justify-center mt-8">
          <div className="w-full lg:w-2/3">
            <EnquiryForm />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeContainer;
