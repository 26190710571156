import React, { useState, useEffect } from 'react';

const HeroSection = () => {
  const [currentImage, setCurrentImage] = useState(0);
  const images = [
    '/images/image.jpg',
    '/images/image2.jpg',
    '/images/image3.jpg',
    '/images/image4.jpg',
    '/images/image5.jpg'
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImage((prevImage) => (prevImage + 1) % images.length);
    }, 3000); 
    return () => clearInterval(interval);
  }, [images.length]);

  return (
    <section className="relative h-screen z-10">
      <div className="absolute inset-0 bg-gradient-to-r from-transparent via-transparent to-transparent">
        
        <img
          src={images[currentImage]}
          alt="Product slideshow"
          className="w-full h-full object-cover"
        />
      </div>
    </section>
  );
};

export default HeroSection;
