import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
// import HeroSection from './containers/HeroSection';
import Home from './pages/Home';
import Shop from './pages/Shop';
import Cart from './pages/Cart';
import '@fortawesome/fontawesome-free/css/all.min.css';
import { Featured } from './pages/Featured';
import Login from './pages/Login';
import Register from './pages/Register';


const App = () => {
  return (
    <Router>
      <div className="flex flex-col min-h-screen">
        <Header />
        {/* <HeroSection /> */}
        <div className="flex-grow">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/shop" element={<Shop />} />
            <Route path="/cart" element={<Cart />} />
            <Route path="/featured" element={<Featured />} />
            <Route path="login" element={<Login />} />
            <Route path="/register" element={<Register />} />
          </Routes>
        </div>
        <Footer />
      </div>
    </Router>
  );
};

export default App;
