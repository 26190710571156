import React from "react";
import { Link } from "react-router-dom";

const ProductCard = ({ image, title, link }) => (
  <div className="overflow-hidden rounded-lg shadow-lg bg-white transition-transform transform hover:scale-105">
    <a href={link} className="block">
      <img
        src={image}
        alt={title}
        className="w-full h-72 object-contain p-4"
      />
    </a>
    <div className="p-4 text-center">
      <h3 className="text-lg font-semibold">{title}</h3>
    </div>
  </div>
);


export default ProductCard;
