import React, { useState } from 'react';

const EnquiryForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    phone: '',
    email: '',
    city: '',
    message: '',
    requirement: 'Personal', // Default to 'Personal'
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Add logic to handle form submission (e.g., send data to a backend)
    console.log(formData);
  };

  return (
    <div className="w-full lg:w-5/4">
      <form onSubmit={handleSubmit} className="border-2 space-y-4 bg-white p-6 rounded-lg shadow-lg">
        <h3 className="text-md font-bold font-regular text-center mb-8">Enquiry</h3>
        <div>
          <label className="block text-sm font-medium">Name</label>
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleChange}
            className="w-full px-3 py-2 border rounded-lg"
            required
          />
        </div>

        <div>
          <label className="block text-sm font-medium">Phone Number</label>
          <input
            type="tel"
            name="phone"
            value={formData.phone}
            onChange={handleChange}
            className="w-full px-3 py-2 border rounded-lg"
            required
          />
        </div>

        <div>
          <label className="block text-sm font-medium">Email</label>
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            className="w-full px-3 py-2 border rounded-lg"
            required
          />
        </div>

        <div>
          <label className="block text-sm font-medium">City</label>
          <input
            type="text"
            name="city"
            value={formData.city}
            onChange={handleChange}
            className="w-full px-3 py-2 border rounded-lg"
            required
          />
        </div>

        <div>
          <label className="block text-sm font-medium">Message</label>
          <textarea
            name="message"
            value={formData.message}
            onChange={handleChange}
            className="w-full px-3 py-2 border rounded-lg"
            required
          ></textarea>
        </div>

        <div>
          <label className="block text-sm font-medium mb-2">Requirement</label>
          <div className="space-x-4">
            <label className="inline-flex items-center">
              <input
                type="radio"
                name="requirement"
                value="Personal"
                checked={formData.requirement === 'Personal'}
                onChange={handleChange}
                className="form-radio"
              />
              <span className="ml-2">Personal</span>
            </label>

            <label className="inline-flex items-center">
              <input
                type="radio"
                name="requirement"
                value="Project"
                checked={formData.requirement === 'Project'}
                onChange={handleChange}
                className="form-radio"
              />
              <span className="ml-2">Project</span>
            </label>
          </div>
        </div>

        <button
          type="submit"
          className="w-full py-2 px-4 bg-blue-500 text-white font-semibold rounded-lg hover:bg-blue-600"
        >
          Submit
        </button>
      </form>
    </div>
  );
};

export default EnquiryForm;
